<template>
    <PricePage />
</template>

<script>
import PricePage from '../components/PricePage.vue'

export default {
    components: {
        PricePage
    },
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    },
}
</script>

